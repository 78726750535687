import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        identity:"",//当前身份
        token: '',
        type: 'wap', //  android#ios#wap
    },
    mutations: {
        setToken(state, data) {
            state.token = data
        },
        setType(state, data) {
            state.type = data
        },
        setIdentity(state, data){
            state.identity = data
        },
    },
    actions: {},
    modules: {},
    plugins: [createPersistedState()]
})