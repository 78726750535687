import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vant from 'vant';
import VueI18n from 'vue-i18n'
// import zh from './assets/i18n/zh'
// import en from './assets/i18n/en'
// import vi from './assets/i18n/vi'
import request from './utils/request'
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'vi',
    messages: {
        'zh-cn': '', // 中文语言包
        'en-us': '', // 英文语言包
        'vi': '' // 越南语言包
    }
})
Vue.config.productionTip = false
Vue.prototype.$http = request


new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')