import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: '/guide' },
    {
        path: '/index',
        name: 'Index',
        component: () =>
            import ('../views/Index.vue'),
        children: [{
                path: '/home',
                name: 'Home',
                component: () =>
                    import ('../views/Home.vue')
            },
            {
                path: '/mine',
                name: 'Mine',
                component: () =>
                    import ('../views/Mine.vue')
            },
        ]
    },
    {
        path: '/tabber',
        name: 'Tabber',
        component: () =>
            import ('../views/Tabber.vue'),
        children: [{
                path: '/guide',
                name: 'Guide',
                component: () =>
                    import ('../views/Guide.vue')
            },
            {
                path: '/login',
                name: 'Login',
                component: () =>
                    import ('../views/Login.vue')
            },
            {
                path: '/register',
                name: 'Register',
                component: () =>
                    import ('../views/Register.vue')
            }
        ]
    },
    {
        path: '/grade',
        name: 'Grade',
        component: () =>
            import ('../views/Grade.vue')
    },
    {
        path: '/gradeDetail',
        name: 'GradeDetail',
        component: () =>
            import ('../views/GradeDetail.vue')
    },
    {
        path: '/codedetail',
        name: 'CodeDetail',
        component: () =>
            import ('../views/CodeDetail.vue')
    },
    {
        path: '/code',
        name: 'Code',
        component: () =>
            import ('../views/Code.vue')
    },
    {
        path: '/supplier',
        name: 'Supplier',
        component: () =>
            import ('../views/Supplier.vue')
    },
    {
        path: '/customer',
        name: 'Customer',
        component: () =>
            import ('../views/Customer.vue')
    },
    {
        path: '/transaction',
        name: 'Transaction',
        component: () =>
            import ('../views/Transaction.vue')
    },
    {
        path: '/service',
        name: 'Service',
        component: () =>
            import ('../views/Service.vue')
    },
    {
        path: '/set',
        name: 'Set',
        component: () =>
            import ('../views/Set.vue')
    },
    {
        path: '/passwordEdit',
        name: 'PasswordEdit',
        component: () =>
            import ('../views/PasswordEdit.vue')
    },
    {
        path: '/bind',
        name: 'Bind',
        component: () =>
            import ('../views/Bind.vue')
    },
    {
        path: '/erpanel',
        name: 'ErpPanel',
        component: () =>
            import ('../views/ErpPanel.vue')
    },//
    {
        path: '/GoodsPriceSup',
        name: 'GoodsPriceSup',
        component: () =>
            import ('../views/GoodsPriceSup.vue')
    },
    {
        path: '/GoodsPriceCus',
        name: 'GoodsPriceCus',
        component: () =>
            import ('../views/GoodsPriceCus.vue')
    },
]


const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (!store.state.token && to.path != '/login' && to.path != '/register' && to.path != '/guide') { // 没有登录
        next('/guide')
    } else {
        next()
    }
})

export default router