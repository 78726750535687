let utils = {
    formatDate: (date) => {
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? '0' + m : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return y + '-' + m + '-' + d;
    },
    isWechat(){
        let ua = navigator.userAgent.toLowerCase();
        let isWeixin = ua.indexOf("micromessenger") != -1;
        return isWeixin
    }
}
export default utils