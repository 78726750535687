<template>
    <div id="app">
        <van-nav-bar
                v-if="navBarShow"
                :title="name"
                :left-text="$t('返回')"
                left-arrow
                :fixed="true"
                :placeholder="true"
                @click-left="goBack"
        />
        <router-view/>
        <van-popup v-model="updateShow" :close-on-click-overlay="false">
            <div class="version-main">
                <div class="version-title">{{ $t("版本更新") }}</div>
                <div class="version-desc">{{ version.ver_desc }}</div>
                <div class="version-group">
                    <van-button
                            class="version-btn"
                            type="primary"
                            @click="download(version.apk)"
                    >{{ $t("更新") }}
                    </van-button
                    >
                    <van-button
                            class="version-btn"
                            style="margin-left: 5vw"
                            v-if="!version.is_force"
                            type="default"
                            @click="updateShow = false"
                    >{{ $t("取消") }}
                    </van-button
                    >
                </div>
            </div>
        </van-popup>
    </div>
</template>

<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 14px;
        color: #2c3e50;
    }

    .van-field__button,
    .van-field__left-icon {
        display: flex;
        align-items: center;
    }

    .van-tabs__wrap {
        border-bottom: 1px solid #f5f5f5;
    }

    .van-tab .van-tab__text {
        color: #888;
    }

    .van-tab--active .van-tab__text {
        color: #5d74f2;
    }

    .van-collapse-item__content {
        background-color: #f5f6fa !important;
    }

    .van-field__label {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .version-main {
        width: 80vw;
        padding: 0 15px;
    }

    .version-title {
        height: 45px;
        line-height: 45px;
        text-align: center;
        border-bottom: 1px solid #e1e1e1;
    }

    .version-desc {
        padding: 10px 0;
        min-height: 70px;
    }

    .version-group {
        display: flex;
        justify-content: center;
        padding-bottom: 15px;
    }

    .version-btn {
        flex: 1;
        height: 35px !important;
    }

    // .van-picker-column .van-ellipsis {
    //   white-space: pre-wrap;
    //   padding: 0 16px;
    // }
</style>

<script>
    import {Toast} from "vant";
    import utils from "./utils/utils";
    import config from './utils/config'

    export default {
        name: "Set",
        data() {
            return {
                name: "", // 顶部栏标题
                navBarShow: false, // 是否显示顶部栏
                updateShow: false, // 更新弹窗
                version: "", // 版本信息
                countrys: [],//国家区号
            };
        },
        mounted() {
            document.addEventListener(
                // 监听是否安卓app内
                "plusready",
                () => {
                    this.$store.commit("setType", "android");
                },
                false
            );
            if (!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
                // pc打开跳转至pc端
                window.location.href = config.url;
            }
            if (!utils.isWechat()) {
                // 是否微信,不是显示顶部导航栏
                this.navBarShow = true;
            }
            if (this.getQueryVariable("icode")) {
                localStorage.setItem("icode", this.getQueryVariable("icode"));
            }
            if (this.getQueryVariable("kcode")) {
                localStorage.setItem("kcode", this.getQueryVariable("kcode"));
            }
            this.getLang();
            this.siteConfig();
        },
        methods: {
            download(url) {
                // 下载新版本
                window.location.href = url;
                this.updateShow = false;
                Toast(this.$t("请留意手机任务栏查看下载进度"));
            },
            getLang() {
                // 语言确认事件
                let lang = this.$i18n.locale;
                this.$http
                    .post("/v1/getLang", {
                        lang,
                    })
                    .then((res) => {
                        if (res.code == 200) {
                            this.$i18n.setLocaleMessage(lang, JSON.parse(res.data));
                        }
                    });
            },
            goBack() {
                // 返回上一页
                this.$router.go(-1);
            },
            goBind() {
                //绑定信息
                this.$router.push('/bind')
            },
            siteConfig() {
                // 获取客户服务信息
                this.$http.post("/v1/siteConfig").then((res) => {
                    if (res.code == 200) {
                        this.name = res.data.name;
                        document.title = res.data.name;
                        if (this.$store.state.type == "android" && res.data.version) {
                            this.version = res.data.version;
                            this.countrys = res.data.countrys;
                            this.updateShow = true;
                        }
                    }
                });
            },
            getQueryVariable(variable) {
                let query = window.location.search.substring(1);
                let vars = query.split("&");
                for (let i = 0; i < vars.length; i++) {
                    let pair = vars[i].split("=");
                    if (pair[0] == variable) {
                        return pair[1];
                    }
                }
                return false;
            },
        },
        getLang() {
        },
    };
</script>

