import axios from 'axios';
import store from '../store'
import router from '../router'
import { Toast } from 'vant';
import config from './config'
const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    baseURL: config.baseUrl,
    timeout: 12000
});
service.interceptors.request.use(
    config => {
        config.headers['token'] = store.state.token;
        config.headers['lang'] = localStorage.getItem("lang") || 'vi';
        config.headers['version'] = 8
        config.headers['type'] = store.state.type;
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data.code == "200") {
                return response.data;
            } else if (response.data.code == "101" || response.data.code == "102") {
                Toast(response.data.msg)
                setTimeout(() => {
                    router.push("/login")
                }, 1500)
            } else {
                Toast(response.data.msg)
            }
        } else {
            if(response.data.msg){
                Toast(response.data.msg)
            }
            Promise.reject();
        }
    },
    error => {
        return Promise.reject(error);
    }
);

export default service;